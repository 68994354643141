import React, { useEffect, useState } from 'react';
import "./Footer.css";
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import { IoIosContact } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { BiMessageRounded } from "react-icons/bi";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' }); // Clear any previous validation errors
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
      isValid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'Invalid email address';
      isValid = false;
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log(formData); // Log the form data values
      // Here you can perform further actions like sending the form data to a server
    }
  };

  useEffect(() => {

  }, []);

  return (
    <>
      <section className='section'>
        <div className="container">
          <div className="row gap-0">
            <div className="col-md-6">
              <h2 className="font-weight-bold text-light contact-header">- Contact Us</h2>
              <p className="custom-opacity-font ">Do you have any query related to services or maintenance.?
                Feel free to ask us, we will get back to you soon.</p>
              <div className="row">
                <div className="col-md-6 box-callUs">
                  <h5 className='h5' >Call Us</h5>
                  <a className='text-decoration-none text-light' href='tel:+971505080445'>
                    <span className='contact-info-header'>Phone</span><br />
                    <span className='phone-number mt-2'>	+971 6532 3881 </span>
                  </a>
                  <h5 className='h5'> Mail Us </h5>
                  <span className='contact-info-header'>Email</span><br />
                  <a href='mailto:dasautoservices@gmail.com' className='text-decoration-none text-light'>
                    <span className='contact-info'>dasautoservices@gmail.com</span>
                  </a>

                </div>               <div className="col-md-6 text-light box-callUs">
                  <h5 className='h5' > Our Locations</h5>
                  <span className='contact-info-header '>Das Auto Services LLC, Sharjah</span><br />

                  <div className="row mt-4">
                    <div className="col md-6">
                      <span className='h5'>Social Media</span><br />
                      <div className='social-links mt-1 d-flex gap-2 '>
                        <div>
                          <a href='https://www.facebook.com/dasautoservicesLLC/' className='social-btn' >
                            <FaFacebookF />
                          </a>
                        </div>
                        <div>
                          <a href='/#' className='social-btn' >
                            <FaInstagram />
                          </a>
                        </div>
                        <div>
                          <a href='/#' className='social-btn' >
                            <FaTwitter />
                          </a>
                        </div>
                        <div>
                          <a href='/#' className='social-btn' >
                            <FaLinkedinIn />
                          </a>
                        </div>                     </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <h2 className="font-weight-bold text-light contact-header">- Write Us</h2>
              <form className="form-custom" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-12 custom-input">
                    <IoIosContact className='icon-contact' />
                    <input className='border-0 form-control' placeholder="Name" type="text" name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 custom-input">
                    <MdEmail className='icon-contact' />
                    <input className='border-0 form-control' placeholder="Email" type="text" name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && <span style={{ color: "red" }}>{errors.email}</span>}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 custom-input">
                    <BiMessageRounded className='icon-contact' />
                    <textarea maxLength={5000} className='form-control border-0 message-box' placeholder="Message" type="text" name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                    {errors.message && <span style={{ color: "red" }}>{errors.message}</span>}
                  </div>
                </div>
                <div className="row ms-1">
                  <button className='submit-btn'>SUBMIT NOW</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <div className="main-footer text-light w-100">
        <p>
          {new Date().getFullYear()} © Das Auto <span className='text-light'>Services LLC</span>
          - Copyright All Rights Reserved
        </p>
      </div>
    </>
  );
};

export default Footer;
