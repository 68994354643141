import { useEffect } from 'react';

import { Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

import "./Home.css"
import "aos/dist/aos.css";

import { FaFacebookF } from "react-icons/fa";


import Expertises from '../Expertises/Expertises';
import Strategy from '../Strategy/Strategy';
import ContactUs from '../Footer/ContactUs';
import Cases from '../Cases/Cases';
import Aos from 'aos';
import AboutHome from '../AboutHome/AboutHome';
function Home() {

  useEffect(() => {
    Aos.init({ duration: 1000 });

  }, []);
  return (
    <>
      <div className="home">
        <div className='position-relative'>
          <Carousel
            fade
            className='home-carousel'>

            <Carousel.Item className='carousel-item'>
              <Image src='/workshop-img/img1.jpg' text="First slide" className='carousel-img object-fit-cover w-100 h-100' />
              <Carousel.Caption>
                <h3 data-aos="fade-down"><span style={{ visibility: "hidden" }}></span><br />AC Specialist</h3>
                <a href='#expertises'>
                  <button data-aos="fade-down" >LEARN MORE</button>
                </a>

                <hr className='mt-3 w-100' />

              </Carousel.Caption>

            </Carousel.Item>
            <Carousel.Item>
              <Image src='/workshop-img/img2.jpg' className='carousel-img object-fit-cover w-100 h-100' text="Second slide" />
              <Carousel.Caption>
                <h3>Independent Specialist of<br/>Honda, Jeep, Dodge and Chrysler</h3>
                <a href='#expertises'>

                  <button >LEARN MORE</button>
                </a>
                <hr className='mt-3 w-100' />

              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <Image src='/workshop-img/img3.jpg' className='carousel-img object-fit-cover w-100 h-100' text="Third slide" />
              <Carousel.Caption>
                <h3>Check up <br /> With Advanced Diagnostic Tools</h3>
                <a href='#expertises'>
                  <button className='mt-5'>LEARN MORE</button>
                </a>

                <hr className='mt-3 w-100' />

              </Carousel.Caption>
            </Carousel.Item>

          </Carousel>

          <div className="container d-none d-lg-block">
            <div className="home-contact-div z-3  row">
              <div className="col-md-4 contact-info">
                <p className='top-text ms-1'>-Are You Looking for a</p><br />
                <p className='middle-text'>Car Service Or Repaire?</p><br />
                <p className='bottam-text col-lg-12'>We got the experience and technology</p>

              </div>
              <div className=" col-md-3  contact-box contact-info">
                <div className='call '>
                  call Us Now <br />  <span className='number'>

                    <a href='tel:+971505080445' className='text-decoration-none text-light'>+971 50 5080445</a>

                  </span>
                </div>
              </div>
              <div className=" col-md-3  box- contact-box contact-info">
                <div className='call '>
                  Email   Us Now <br />  <span className='number'>
                    <a href='mailto:dasautoservices@gmail.com' className='text-decoration-none text-light'> dasautoservices@gmail.com</a>
                  </span>
                </div>
              </div>
              <div className="  col-md-2 box-4 contact-box contact-info">
                <div className='call '>
                  Follow  Us Now <br /> <a href='https://www.facebook.com/dasautoservicesLLC/' className='social'><FaFacebookF /> </a><span className='number'>
                  </span>
                </div>
              </div>

            </div>
          </div>

        </div>



      </div>
      <AboutHome />
      <Expertises animation='fade-down' />
      <Strategy />
      <Cases />
      <ContactUs />
    </>
  );
}

export default Home;