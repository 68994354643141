import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { Link } from 'react-router-dom'
import "./Navbar.css"
import { Image } from 'react-bootstrap';

const Header = () => {
    const [headerClass, setHeaderClass] = useState("")

    const listenScrollEvent = (event) => {
        if (window.scrollY < 73) {
            return setHeaderClass("header")
        } else if (window.scrollY > 70) {
            return setHeaderClass("header-bg")
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () =>
            window.removeEventListener('scroll', listenScrollEvent);
    }, []);
  
        const scrollToTop = () => {
          window.scrollTo(0, 0)
        }

    return (

        <Navbar expand="lg" className={`${headerClass} nav position-fixed mt-3  z-3 w-100 bg-transparent`}>
            <Container>
                <Navbar.Brand >
                    <Link to={'/'} onClick={scrollToTop}>

                    <Image src={'./workshop-img/logo.png'} />
                    </Link>
                   
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto" >
                        <Link className="nav-link" to={'/'} onClick={scrollToTop}>HOME</Link>
                        <Link className="nav-link" to={'/about'} onClick={scrollToTop}>ABOUT US</Link>
                        <Link className="nav-link" to={'/cars'} onClick={scrollToTop}>CARS</Link>
                        <Link className="nav-link" to={'/sell'} onClick={scrollToTop}>SELL YOUR CARS</Link>
                        <Link className="nav-link" to={"/services/AirConditioner"} onClick={scrollToTop}>AC SERVICE</Link>
                        <Link className="nav-link" to={'/services'} onClick={scrollToTop}> SERVICE</Link>
                        <Link className="nav-link" to={'/contact'} onClick={scrollToTop}>CONTACT US</Link>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    )
}

export default Header