import React from 'react'
import BreadcrumbUs from '../Breadcrumb/BreadcrumbUs'
import "./ServiceMaintenance.css"
const ServiceMaintenance = () => {
    return (
        <>
            <BreadcrumbUs title="- Service and Maintenance" subTitle="Service and Maintenance Details" currentPage="SERVICE & MAINTENANCE" prevesPage="SERVICE" />
            <div className="ServiceMaintenance">
                <div className="container">
                    <div className="row mx-auto mt-5">
                        <div className="col-md-10">
                            <h4><strong>Lube service</strong></h4>
                            <p className='maintenance-sub-heading'>
                                <strong>VEHICLE INSPECTION CHECKLIST AND PROCEDURES</strong>
                            </p>
                            <p className='maintenance-sub-content'>
                                When it comes to vehicle inspection services, DAS AUTO SERVICES is proud to offer the highest quality vehicle assessments around. Whether you're bringing your car in for routine maintenance, repairs or a checkup, we complete an extensive vehicle inspection checklist to make sure your car is performing its best under the hood. Our expert auto technicians evaluate all the major operating systems of your vehicle during their vehicle inspection procedures.
                            </p>
                            <p className='maintenance-sub-heading'>
                                <strong>CHECKLIST FOR VEHICLE INSPECTION</strong>
                            </p>
                            <ul>
                                <li><strong>Tires</strong></li>
                                Inspect tire tread, pressure and tire wear.
                            </ul>
                            <ul>
                                <li><strong>Batteries</strong></li>
                                Check voltage, secure battery tie-downs and conduct a charge/discharge load test.

                            </ul>
                            <ul>
                                <li><strong>Brakes</strong></li>
                                Check the brake fluid and evaluate wear on the drum, brake pads and rotors.

                            </ul>
                            <ul>
                                <li><strong>Steering and Suspension</strong></li>
                                Examine the power steering belt, power steering fluid level, power steering pump and check for fluid leaks.
                            </ul>
                            <ul>
                                <li><strong>Exterior</strong></li>
                                Complete exterior inspection.
                            </ul>
                            <ul>
                                <li><strong>Safety Lights</strong></li>
                                Inspect headlights, brake lights, hazard lights and turn signals.
                            </ul>
                            <ul>
                                <li><strong>Windshield Wipers</strong></li>
                                Examine the windshield and wiper blades.

                            </ul>
                            <ul>
                                <li><strong>Engine</strong></li>
                                Engine inspection for leaks, damage and maintenance check will be completed.


                            </ul>
                            <ul>
                                <li><strong>Fluid Levels</strong></li>
                                Check the oil levels, coolant levels and washer fluid levels.


                            </ul>

                            <ul>
                                <li><strong>Belts</strong></li>
                                Inspect the belt operations.

                            </ul>
                            <ul>
                                <li><strong>Hoses</strong></li>
                                Check hoses for leaks and other issues.
                            </ul>
                            <ul>
                                <li><strong>Exhaust System</strong></li>
                                Examine the exhaust manifold, catalytic converter, tailpipes and muffler for damage, cracks and leaks.
                            </ul>
                            <p className='maintenance-sub-content'>
                                A full vehicle inspection checklist will be completed by a DAS AUTO SERVICES technician to make sure your car is safe and performing at its best. If you need a vehicle inspection,
                                DAS AUTO SERVICES technicians are ready to evaluate and inspect your car.
                            </p>
                            <h4><strong>Minor service</strong></h4>

                            <ul>
                                <li>One year or 10000 km service (which ever comes earlier)</li>
                                <li>Replace of engine oil and oil filter</li>
                                <li>Check list</li>
                            </ul>

                            <h4><strong>Major service</strong></h4>
                            <p className='maintenance-sub-content'>
                                40000 km or four year service (which ever comes earlier)
                            </p>
                            <ul >
                                <li className='custom-list'>Replace of  </li>
                                <li>Engine oil and oil filter</li>
                                <li>Transmission oil</li>
                                <li>Transmission filter (if required)</li>
                                <li>Air filter </li>
                                <li> Coolant</li>
                                <li>Brake fluid </li>
                                <li> Power steering fluid</li>
                                <li> Spark plugs (if required)</li>
                                <li>Differential oil (if required) </li>
                                <li>4*4 oil (if required)</li>
                                <li>Full check up with diagnostic tool and erase all trouble codes</li>
                            </ul>
                            <p className='maintenance-sub-content'>
                                If you are not sure which service you require, just call us on <span style={{ color: "#FA4141" }}>0505080445</span> and we will be pleased to help.
                            </p>


                        </div>
                        <div className="col-md-2">
                            <div className="row mb-3">
                                <div className="col-md-12 col-sm-2">
                                    <img  className='fit-img' src="./workshop-img/Workshops01.jpg" alt="maintenens" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img className=' fit-img' src="/workshop-img/Evaluation.jpg" alt="maintenens"  />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img  className=' fit-img'  src="/workshop-img/WorkshopsSupport05.jpg" alt="maintenens" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img  className=' fit-img'  src="/workshop-img/maintenens.jpg" alt="maintenens" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img  className=' fit-img'  src="/workshop-img/carousel-image/ServiceCase02.jpg" alt="maintenens" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img  className=' fit-img'  src="/workshop-img/carousel-image/DiagnosticsCase03.jpg" alt="maintenens" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img  className=' fit-img'  src="/workshop-img/carousel-image/05.jpg" alt="maintenens" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img  className=' fit-img'  src="/workshop-img/carousel-image/04.jpg" alt="maintenens" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ServiceMaintenance