import React from 'react'
import BreadcrumbUs from '../Breadcrumb/BreadcrumbUs'
import "./ElectricalWorks.css"
const ElectricalWorks = () => {
    return (
        <>
            <BreadcrumbUs title="- Electrical Works" subTitle="Electrical Works Details" currentPage="ELECTRICAL WORKS" prevesPage="SERVICE" />
            <div className="electricalworks">
                <div className="container">
                    <div className="row mx-auto mt-5">
                        <div className="col-md-10 ">
                            <p className='electricalworks-sub-content mx-5'>
                                Most mechanical functions nowadays depend on electrical components.
                                Your car is controlled by a complex network of wires, sensors,
                                and computers working together.
                                If communication along these wires is interrupted,
                                it could have major consequences for several components of your vehicle.
                            </p>
                            <p className='electricalworks-sub-content mx-5'>
                                Sometimes what seems like a major failure is merely a software bug.
                                Computers control your car. Starters, alternators,
                                air conditioning systems, and many other components are now computer driven.
                                Our technicians will make sure you don't waste money on diagnostics and parts.
                            </p>
                            <p className='electricalworks-sub-content mx-5'>
                                Your battery could also be the culprit of an electrical problem.
                                A loose cable, a faulty wire, or a dirty connection could appear to be a bigger problem than it is. If your battery isn't receiving a charge,
                                the alternator might need replacement. But you'll receive a full system check, not just a recommendation for parts you might not need. We check the basics before we recommend any major repairs. We care about your car and we respect your budget.
                            </p>

                        </div>
                        <div className="col-md-2">

                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img className=' fit-img' src="/workshop-img/Electrical1.jpg" alt="maintenens" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img className=' fit-img' src="/workshop-img/Electrical2.jpg" alt="maintenens" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img className=' fit-img' src="/workshop-img/Electrical3.jpg" alt="maintenens" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ElectricalWorks