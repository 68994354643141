
import BreadcrumbUs from '../Breadcrumb/BreadcrumbUs'

import SellFormUs from './SellFormUs';


const SellCar = () => {



  return (
    <>
      <BreadcrumbUs title="- SELL YOU CAR " subTitle="Your Car, Your Cash: Sell Now!" currentPage="SELL YOur CARS" />

      <div className="form-continer">
        <div className="container">
          <div className="row mx-auto">
            <div className="col-md-12 align-content-center">

              <div className="row ">

                <div className="col-md-6 mx-auto mt-5 mb-5">
                  <div className="col-12">
                    <h2 className="mb-4">Sell Your Car Form</h2>
                  </div>
                  <SellFormUs />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  )

}

export default SellCar