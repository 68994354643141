import { Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import "./StrategyCarousel.css"

function StrategyCarousel() {
  

  return (
    <>
      <section>
        <div className="">
          <div className="">
            <h2 className='text-light'>- Our Strategy</h2>
            <Carousel className='review-Carousel' >

              <Carousel.Item interval={1000}>
                <Image src='/workshop-img/222222.png' style={{ height: "360px" }} text="First slide" />
                <Carousel.Caption className='d-flex'>
                  <div className='content '>
                    <div className="date-box">
                      <span className="month">Sep</span><br />
                      <span className="day">01</span>
                    </div>
                  </div>
                  <div className="step-content">
                    <h5>The first step</h5><br />
                    <strong>Be Civil and Respectful</strong>
                    <p className='mt-3 text-light'>
                      Not all customers are friendly. No matter what happens, we know it is vital that to remain civil and respectful.
                      A lot of the time even the angriest person will calm down if they are treated correctly.
                    </p>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={500}>
                <Image src='/workshop-img/222222.png' style={{ height: "360px" }} text="Second slide" />
                <Carousel.Caption className='d-flex'>
                  <div className='content '>
                    <div className="date-box">
                      <span className="month">Sep</span><br />
                      <span className="day">02</span>
                    </div>
                  </div>
                  <div className="step-content">
                    <h5>The first step</h5><br />
                    <strong>Start with a Smile</strong>
                    <p className='mt-3 text-light'>
                      Smiling at a customer when they come in, puts them at ease and shows them that we are ready to help them.
                      Smiles warm people up and make great ice breakers.
                    </p>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <Image src='/workshop-img/222222.png' style={{ height: "360px" }} text="Third slide" />
                <Carousel.Caption className='d-flex'>
                  <div className='content '>
                    <div className="date-box">
                      <span className="month">Sep</span><br />
                      <span className="day">03</span>
                    </div>
                  </div>
                  <div className="step-content">
                    <h5>The third step</h5><br />
                    <strong>Respond Promptly and Accurately</strong>
                    <p className='mt-3 text-light'>
                      No one likes to be kept waiting for too long. When a customer or client asks for something, we make sure that to respond in a timely fashion. More importantly, we make sure that we are giving them the correct information. Misinformation will cost more than just a sale;
                      it has the potential to break business' reputation.
                    </p>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>

        </div>
      </section>
    </>

  );
}

export default StrategyCarousel;