import React from 'react'
import { Image } from 'react-bootstrap'
import "./AboutHome.css";

import CountUp from 'react-countup';
import { Link } from 'react-router-dom';

const AboutHome = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }
  return (
    <>
      <div className="container-fluid ">
        <div className="container-box">
          <div className="row">
            <div className="col-md-7 col-md-6 paragraph-box">
              <h3>-About Us</h3>
              <div className='content'>
                <p>
                  Das Auto International FZD Deal in the trading of new and used Cars, Four Wheel Drives (Highline & Exotic cars),by which we have earned an excellent reputation in this contribution towards our success.
                </p>
                <p >
                  Das Auto Services LLC is one of the most trusted Independent Honda Specialist in Sharjah offers top-of-the-line Service to our customers. Our state-of-the-art facility features the most current diagnostic and repair equipment available, and our factory-trained technicians will deliver the most efficient and quality vehicle care.
                </p>
                <Link  to={'/about'} onClick={scrollToTop} >

                <button className='btn custom-border-width font-weight-semibold text-uppercase'>READ MORE</button>
                </Link>
              </div>
            </div>
            <div className="col-md-5  card-boxs" >
              <div className="row">
                <div className="col-md-6  record-box2 border-set1 box-bg">
                  <div className="row ">
                    <div className="col-2 mt-3   ">
                      <Image src='/workshop-img/date.png' alt="png" className='d-inline  ' />

                    </div>
                    <div className="col-10 text-md-left lh-1 mt-1">
                      <span className='count2 text-light' > <CountUp
                        start={0}
                        end={15}
                        duration={6}
                      />+</span><br />
                      <span className='rec-text2'> Years in Business</span>

                    </div>
                  </div>
                </div>

                {/* </div> */}
                <div className="col-md-6  record-box2">
                  <div className="row ">
                    <div className="col-2 mt-3   ">
                      <Image src='/workshop-img/start.png' alt="png" className='d-inline  ' />

                    </div>
                    <div className="col-10 text-md-left lh-1 mt-1">
                      <span className='count2 text-light' >
                        <CountUp
                          start={0}
                          end={2000}
                          duration={6}
                        />+</span><br />
                      <span className='rec-text2'>Successfull Cases</span>

                    </div>
                  </div>
                </div>
              </div>
              <div className="row border-set">
                {/* <div className="col-md-12 col-lg-6 record-box d-flex  border-set">
                  <Image src='/workshop-img/smail.png' alt="png" className='d-inline' />
                  <span className='count'>
                    <CountUp
                      start={0}
                      end={240}
                      duration={6}
                    />+
                  </span>
                  <span className='rec-text'>Satisfied Clients</span>
                </div> */}
                <div className="col-md-6  record-box2 ">
                  <div className="row ">
                    <div className="col-2 mt-3   ">
                      <Image src='/workshop-img/smail.png' alt="png" className='d-inline ' />

                    </div>
                    <div className="col-10 text-md-left lh-1 mt-1">
                      <span className='count2 text-light' >
                        <CountUp
                          start={0}
                          end={240}
                          duration={6}
                        />+

                      </span><br />
                      <span className='rec-text2'>Satisfied Clients</span>

                    </div>
                  </div>
                </div>
                {/* <div className="col-md-12 col-lg-6 record-box box-bg d-flex ">
                  <Image src='/workshop-img/certificats.png' alt="png" className='d-inline' />
                  <span className='count'>
                    <CountUp
                      start={0}
                      end={250}
                      duration={6}
                    />+
                  </span>
                  <span className='rec-text'>Certifications</span>
                </div> */}
                <div className="col-md-6  record-box2 border-set2 box-bg">
                  <div className="row ">
                    <div className="col-2 mt-3   ">
                      <Image src='/workshop-img/certificats.png' alt="png" className='d-inline  ' />

                    </div>
                    <div className="col-10 text-md-left lh-1 mt-1">
                      <span className='count2 text-light' >
                        <CountUp
                          start={0}
                          end={130}
                          duration={6}
                        />+</span><br />
                      <span className='rec-text2'> Certifications</span>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutHome