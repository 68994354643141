import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "./BreadcrumbUs.css"
import { Link } from 'react-router-dom';
import React from 'react';
function BreadcrumbUs(props) {

 
  return (
    <Breadcrumb className='bread-crumb '>
      <div className="container mt-2">
        <div className="row">
          <div className="col-md-8 page-header">
            <h1 className='text-light text-uppercase'>
              {props.title}
            </h1>
            <span className='sub-heading '> {props.subTitle}</span>
          </div>
          <div className="col-md-4 link-box">

            <Link className='option link-arrow' to={"/"}>Home</Link>
            {props.prevesPage ? (

              <Link to={"/services"} className='link-arrow-sec'>{props.prevesPage}</Link>

            )
              : ("")}
            <Breadcrumb.Item active className='option'>{props.currentPage}</Breadcrumb.Item>
          </div>


        </div>

      </div>

    </Breadcrumb>
  );
}

export default BreadcrumbUs;