
import React, { useState } from 'react';
import "./Expertises.css";
import { Button, Image, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const expertisesData = [
  {
    imgSrc: './workshop-img/Workshops01.jpg',
    title: 'Workshop Services',
    description: 'Our workshop works includes all type of service.',
    path: "/services"
  },
  {
    imgSrc: './workshop-img/GenuineParts02.jpg',
    title: 'Genuine Parts',
    description: 'We provide genuine parts to our customers.',
    path: " "
  },
  {
    imgSrc: './workshop-img/UsedAutoParts04.jpg',
    title: 'Used Parts',
    description: 'We offer quality used parts to those who look for it.',
    path: " "
  },
  {
    imgSrc: './workshop-img/WorkshopsSupport05.jpg',
    title: 'Workshops Support',
    description: 'We also provide service support to other garages.',
    path: " "
  },
  {
    imgSrc: './workshop-img/BuySell06.jpg',
    title: 'Buy or Sell Your Car',
    description: 'You can buy or sell your used cars at Das Auto.',
    path: "/cars"
  },
  {
    imgSrc: './workshop-img/Evaluation.jpg',
    title: 'Evaluation',
    description: 'We offer vehicle evaluation and certification services.',
    path: "/services/diagnostics"

  }
];

const Expertises = () => {
  const navigate = useNavigate();


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  // const handleShow = () => {
  //   console.log("Clicked.....");
  //   setShow(true);
  // }

  const handleShow = (index) => {
    if (index === 3) {
      setShow(true);
    } else {
     
      navigate(expertisesData[index].path);
    }
  }
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <section id="expertises" className='py-md-4' >
     
      <div className='container '>
        <div className="row">
        <h2 className='text-light mb-3'>- Our Expertises</h2>
        {expertisesData.map((expertise, index) => (
          
            <div className="col-lg-4 col-md-6 my-2 my-md-2" key={index}>
               <Link to={expertise.path} onClick={scrollToTop} >
            <div className="d-flex card-box-container" onClick={() => handleShow(index)}>
                        <div className="img-box">
                          <Image className="image" src={expertise.imgSrc} alt={expertise.title} />
                        </div>
                        <div>
                          <h5 className='text-light mt-3 ms-3 mb-0'>{expertise.title}</h5><br />
                          <p className='m-0 ms-3 text-light'>{expertise.description}</p>
                        </div>
                      </div>
                      </Link>
            </div>
        
        ))}
        </div>
      
     
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>SERVICE SUPORT FOR OTHER WORKSHOPS
            </Modal.Title> */}
            <p className='popup-header'>
            SERVICE SUPORT FOR OTHER WORKSHOPS
            </p>
        </Modal.Header>
        <Modal.Body className='text-center'>
        <p>
        WE ALSO PROVIDE SERVICE SUPPORT TO OTHER GUAREGES IN-
        </p>
        <ul className='text-start ms-3'>
          <li>
          DIAGNOSING PROBLEM FOR PASSENGER CARS
          </li>
          <li>SUPPLY SPARE PARTS ( GENUINE AND USED )</li>
          <li>SUPPLY SPECIAL TOOLS</li>
          <li>TECHNICAL TRAINING AND TECHNICAL SUPPORT</li>
          
        </ul>
        </Modal.Body>
       
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>

    </section>
  );
};

export default Expertises;
