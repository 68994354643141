import React from 'react'
import BreadcrumbUs from '../Breadcrumb/BreadcrumbUs'

const AutomaticTransmission = () => {
    return (
        <>
            <BreadcrumbUs title="-Automatic Transmission" subTitle="Automatic Transmission Services" currentPage="AUTOMATIC TRANSMISSION" prevesPage="SERVICE" />
            <div className="car-detailing">
                <div className="container">
                    <div className="row mx-auto mt-5 mb-5 ms-2 ">
                        <div className="col-md-10 ">
                            <p className='text-light' >
                            WE DO AUTOMATIC TRANSMISSION SERVICES
                            </p>
                        </div>
                        <div className="col-md-2">

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AutomaticTransmission