import React from 'react'
import Slider from '../Carousel/Slider'

const Cases = () => {
  return (
    <Slider/>

  )
}

export default Cases