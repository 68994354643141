import React from 'react'
import BreadcrumbUs from '../Breadcrumb/BreadcrumbUs'
import "./AcService.css"
const AcService = () => {
    return (
        <>

            <BreadcrumbUs title="  -Air Conditioner" subTitle="Vehicle Air Conditioning" currentPage="AIR CONDITIONER" prevesPage="SERVICE" />

            <div className="ac-service-container">
                <div className="row align-items-center">
                    <div className="col-md-8 mx-auto   first-content-box">
                        <p className='about-header'>
                            Das Auto Services specialize in automotive air conditioning repair and have over 20 years experience in servicing car and SUV A/C's. Our technicians are committed to providing personalized, honest service to each customer. We use the latest, up-to-date automotive air conditioning diagnostic equipment and we offer Warranted A/C repairs at fair prices with usually a one day service time.
                        </p>
                        <p className='sub-heading fw-bold'>Why do I need my air conditioning system serviced and/or cleaned?</p>
                        <p className='sub-heading'>The Air Con system in your car is a serviceable item, just as you have to change your oil and air filters from time to time.
                            It is very important to maintain your Air Conditioning system for the following reasons:
                        </p>
                        <ul>
                            <li>Fungi and bacteria can grow in car A/C systems and can cause allergic reactions such as hay-fever, skin irritations, runny noses and itchy eyes.
                            </li>
                            <li>
                                Due to natural leakage through naturally slightly porous pipes, most A/C systems will lose around 15-20% refrigerant every 12 months resulting in a less powerful and less efficient system.
                            </li>
                            <li>
                                If not serviced regularly, your A/C system will put a strain on the compressor which can result in the compressor wearing out quickly, as well as causing costly, increased fuel consumption as it draws on more engine power to run the system.
                            </li>
                        </ul>
                        <p className='sub-heading fw-bold'>What is the difference between servicing and cleaning?</p>
                        <p className='sub-heading fw-bold'>Servicing</p>
                        <p>
                            Manufacturers recommend that a vehicle's air con system is serviced every 2 years to ensure it operates at maximum efficiency. However, there are some indicators that will help you determine if you have a problem with your Air Conditioning system:
                        </p>
                        <ul>
                            <li>
                                The windscreen doesn't clear quickly
                            </li>
                            <li>The interior takes longer to cool down</li>
                            <li>
                                There's a noticeable increase in fuel consumption
                            </li>
                        </ul>
                        <p className='sub-heading fw-bold'>The air-con system service includes:</p>
                        <ul>
                            <li>testing for leaks</li>
                            <li>emptying and recharging the gas in the system</li>

                        </ul>
                        <p className='sub-heading fw-bold'>Cleaning </p>
                        <p>
                            If you notice an odd, particularly musty smell in your car, it is more than likely that the air con system needs proper cleaning. The cost of cleaning the system is additional to the service cost and entails cleaning the entire system with a de-fungicide.

                        </p>
                        <p>If you are not sure which service you require, just call us on <span style={{color:"#FA4141"}}>0505080445</span> and we will be pleased to help.

                        </p>





                    </div>
                </div>
            </div>

        </>


    )
}

export default AcService