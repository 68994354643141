import React from 'react'
import { Image } from 'react-bootstrap'
import "./AboutUs.css"
import CountUp from 'react-countup';
import BreadcrumbUs from '../Breadcrumb/BreadcrumbUs'




const AboutUs = () => {

  return (
    <>

      <BreadcrumbUs title="- About Us" subTitle="Who We Are" currentPage="About" />
      <div className="about-container">
        <div className="row">
          <div className="col-md-9 m-auto first-content-box">
            <p className='about-header'>
              Das Auto Services LLC Deal in the trading of new and used Cars, Four Wheel Drives (Highline & Exotic cars),by which we have earned an excellent reputation in this contribution towards our success .
            </p>
            <p className='sub-heading'>
              Das Auto Services LLC is one of the most trusted Independent Honda Specialist in Sharjah offers top-of-the-line Service to our customers. Our state-of-the-art facility features the most current diagnostic and repair equipment available,
              and our factory-trained technicians will deliver the most efficient and quality vehicle care.
            </p>
            <div className="row contrent-row">
              <div className="col-md-12 sec-content-box">
                <ul>
                  <li className='d-flex  gap-2'>
                    <div className='button-div'>
                      <button className='icon-right-arrow'></button>
                    </div>
                    DAS AUTO SERVICES IS ONE OF THE MOST TRUSTED AND TRAINED INDEPENDENT SPECIALIST IN AFTER SALES SUPPORT IN ALL PREMIUM CARS LIKE HONDA, CHRYSLER, JEEP,DODGE, ,FORD, etc…
                  </li>
                  <li className='d-flex  gap-2'>
                    <div className='button-div'>
                      <button className='icon-right-arrow'></button>
                    </div>
                    WE ARE A WELL KNOWN LLC COMPANY, EQUIPPED WITH EXPERTISED STAFFS FROM DIFFERENT PARTS OF WORLD WITH AN EXPERIENCE OVER 20 YEARS, OPERATING IN SHARJAH FOR PAST 15 YEARS                  </li>
                  <li className='d-flex  gap-2'>
                    <div className='button-div'>
                      <button className='icon-right-arrow'></button>
                    </div>
                    OUR SERVICE ENGINEERS ARE TRAINED IN DIFFERENT BRANDS FROM DIFFERENT PARTS OF WORLD ESPECIALY FROM LEADING CAR MANUFACTURERS
                  </li>
                  <li className='d-flex  gap-2'>
                    <div className='button-div'>
                      <button className='icon-right-arrow'></button>
                    </div>
                    OUR SERVICE IS SUPPORTED WITH MODERN DIAGNOSTICS GADGETS AND REPAIR EQUPMENTS AND WE PROVIDE SUPPORT UPTO OEM LEVEL IN DIAGNOSTICS
                  </li>
                  <li className='d-flex  gap-2'>
                    <div className='button-div'>
                      <button className='icon-right-arrow'></button>
                    </div>
                    WE OFFER PICK UP FOR VEHICLES, OR IF NECESSARY WILL DELIVER YOUR CAR AFTER SERVICE AT YOUR DOOR STEP
                  </li>
                  <li className='d-flex  gap-2'>
                    <div className='button-div'>
                      <button className='icon-right-arrow'></button>
                    </div>
                    WE HAVE STATE OF THE ART SERVICE FACILITY TO SERVE CUSTOMERS, AND TO SATISFY THEIR AUTOMOTIVE NEEDS AND REQUIREMENTS
                  </li>
                  <li className='d-flex  gap-2'>
                    <div className='button-div'>
                      <button className='icon-right-arrow'></button>
                    </div>
                    OUR MOTTO IS: "CUSTOMER NOT FOR LIFE BUT FOR GENERATIONS"
                  </li>
                  <li className='d-flex  gap-2'>
                    <div className='button-div'>
                      <button className='icon-right-arrow'></button>
                    </div>
                    WE WORK AROUND THE CLOCK AND ARE AVAILABLE AT YOUR DOOR STEP 24*7
                  </li>
                  <li className='d-flex  gap-2'>
                    <div className='button-div'>
                      <button className='icon-right-arrow'></button>
                    </div>
                    WE ENSURE FIX RIGHT FIRST TIME AND FOLLOW THE SAME WITH DEVOTION
                  </li>

                </ul>
                <p className='mt-4'>
                  We are able to give a personal service to each of our customers,communicating with them before and after work on their vehicle. Our customers have high standards which we always strive to exceed.
                </p>
                <p>We offer a free collection and delivery service for labor that costs more than AED1000/-
                </p>
                <div className="row mt-4 status-row">
                  <div className="col-md-3 text-center mb-5 lh-1 ">
                    <span className='status-count'>
                      <CountUp
                        start={0}
                        end={15}
                        duration={5}
                      />
                      +</span>
                    <div>
                      <span className='info'>Years in Business</span>
                    </div>
                  </div>
                  <div className="col-md-3 text-center lh-1 mb-5  ">
                    <span className='status-count'>
                      <CountUp
                        start={0}
                        end={2000}
                        duration={6}
                      />+</span>
                    <div>
                      <span className='info'>Successfull Cases</span>
                    </div>
                  </div>
                  <div className="col-md-3 text-center lh-1 mb-5  ">
                    <span className='status-count'>
                      <CountUp
                        start={0}
                        end={240}
                        duration={6}
                      />
                      +</span>
                    <div>
                      <span className='info'>Satisfied Clients</span>
                    </div>
                  </div>
                  <div className="col-md-3 text-center lh-1 mb-5  ">
                    <span className='status-count'>
                    <CountUp
                        start={0}
                        end={130}
                        duration={6}
                      />
                      +</span>
                    <div>
                      <span className='info'>Certifications</span>
                    </div>
                  </div>

                </div>
                <p>
                  We aim to make the process of having your vehicle serviced or repaired as easy as possible whilst never comprimising our standards.
                </p>

                <div className="row mt-4 gx-3 row-box-setting">
                  {/* <div className="col-md-12">
                    <div className="row gap-0"> */}
                  {/* <div className="col-md-4 accordion-button">
                        <Image src={service} alt="service" className='about-img' />
                      </div> */}
                  <div className="col-md-4 col-box-setting">
                    <Image src='/workshop-img/img3.jpg' alt="service" className='about-img' />
                  </div>
                  <div className="col-md-4">
                    <Image src='/workshop-img/img2.jpg' alt="service" className='about-img' />
                  </div>
                  <div className="col-md-4">
                    <Image src='/workshop-img/car-ac-service.jpg' alt="service" className='about-img' />
                  </div>

                  {/* </div>
                  </div> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  )
}

export default AboutUs