import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Slider.css";

import { Link } from "react-router-dom";

const Slider = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 4 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 767, min: 464 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
const sliderImageUrl = [
  
  {
    title: 'Auto Ac',
    category: 'service',
    url: './workshop-img/carousel-image/01.jpg',
    path: '/services/AirConditioner'

  },
  {
    title: 'service &',
    category: 'maintenance',
    url: './workshop-img/carousel-image/ServiceCase02.jpg',
    path: "/services/ServiceMaintenance"
  },
 
  {
    title: 'Diagnostics',
    category: 'service',
    url: './workshop-img/carousel-image/DiagnosticsCase03.jpg',
    path: "/services/diagnostics"
  },

  {
    title: 'Repaire  ',
    category: 'service',
    url: './workshop-img/carousel-image/04.jpg',
    path: "/services/repairs"
  },



  {
    title: 'Electrical  ',
    category: 'Works',
    url: './workshop-img/carousel-image/05.jpg',
    path: "/services/electricalworks"

  },
  {
    title: '24*7 Recovery',
    category: 'service',
    url: './workshop-img/carousel-image/RecoverCase06.jpg',
    path: "/services/recovery"
  },
  {
    title: 'Automatic',
    category: 'service',
    url: './workshop-img/carousel-image/TransmisionCase08.jpg',
    path: "/services/transmission"
  },
  {
    title: 'Call Deatailing',
    category: 'Transmisson',
    url: './workshop-img/carousel-image/DetailingCase10.jpg',
    path: "/services/detailing"
  },

];

  return (
    <div className="parent ">
      <div className="container-fluid">
        <div className="container">
        <div className="row">
          <div className="col-md-12 ">
            <h4 className="pt-4 ms-5 d-inline-block">- Our Cases</h4>
          </div>
        </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Carousel
              responsive={responsive}
              // autoPlay={true}
              swipeable={true}
              draggable={true}
              showDots={true}
              infinite={true}
              partialVisible={false}
              dotListClass="custom-dot-list-style"
            >
              {sliderImageUrl.map((data, index) => {
                return (
                  <Link to={data.path} onClick={scrollToTop} key={index}>
                    <div>
                      <div className="slider" key={index}>
                        <div className="image-container">
                          <img src={data.url} alt={data.title} className="slider-image" />
                        </div>
                        <div className="img-content">
                          <div>
                            <span className="img-title">{data.title}</span><br />
                            <span className="category">{data.category}</span>
                          </div>
                          <div className="custom-arrow"></div>
                        </div>
                      </div>
                    </div>
                  </Link>

                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Slider;
