import React from 'react'
import BreadcrumbUs from '../Breadcrumb/BreadcrumbUs'
import "./Diagnostics.css"

const Diagnostics = () => {
    return (
        <>
            <BreadcrumbUs title="- Diagnostics" subTitle="Vehicle Diagnostics" currentPage="DIAGNOSTICS" prevesPage="SERVICE" />
            <div className="diagnostics">
                <div className="container">
                    <div className="row mx-auto mt-5">
                        <div className="col-md-10">
                            {/* <h4><strong>Lube service</strong></h4> */}
                            {/* <p className='maintenance-sub-heading'>
                        <strong>VEHICLE INSPECTION CHECKLIST AND PROCEDURES</strong>
                    </p> */}
                            <p className='introduction'>
                                With vehicle technology becoming more sophisticated each year, it is essential that workshops invest in the right equipment. At Das Auto Services we have the latest Honda Diagnostic tool (HIM) and DRB-3 star scam that cover Honda, Jeep and chrysler vehicles. We are able to carry our a variety of tasks from resetting service indicators to diagnosing complex engine management and Anti Lock Braking faults.
                            </p>
                            <p className='diagnostics-sub-heading'>
                                <strong>Why is specialist diagnostic equipment needed ?</strong>
                            </p>
                            <p className='diagnostics-sub-content'>
                                When it comes to vehicle inspection services, DAS AUTO SERVICES is proud to offer the highest quality vehicle assessments around. Whether you're bringing your car in for routine maintenance, repairs or a checkup, we complete an extensive vehicle inspection checklist to make sure your car is performing its best under the hood. Our expert auto technicians evaluate all the major operating systems of your vehicle during their vehicle inspection procedures.
                            </p>

                            <p className='diagnostics-sub-heading'>
                                <strong>What faults can be diagnosed ?</strong>
                            </p>
                            <p className='diagnostics-sub-content'>
                                Most of the functions on a modern car are controlled electronically and,
                                when faulty, require specialist diagnostics. These include:
                            </p>
                            <ul>
                                <li><strong>Ignition System</strong></li>
                                <li><strong>Fuel System</strong></li>
                                <li><strong>Exhaust System </strong></li>
                                <li><strong>Cooling System</strong></li>
                                <li><strong>Anti Lock Braking System</strong></li>
                                <li><strong>Electrical System</strong></li>
                                <li><strong>Airbags</strong></li>
                                <li><strong>Transmission</strong></li>
                            </ul>
                            <p className='diagnostics-sub-heading'>
                                <strong>Warning lights</strong>
                            </p>
                            <p className='diagnostics-sub-content'>
                                Most system faults will be shown by a warning light on the dashboard indicating that the fault requires investigation.
                            </p>
                            <p className='diagnostics-sub-content'>
                                If your vehicle has a warning light showing or has a fault that you havent been able to get rectified, please don't hesistate to contact us on <br /> <span style={{ color: "#FA4141" }}>0505080445</span>.
                            </p>




                        </div>
                        <div className="col-md-2">

                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img className=' fit-img' src="/workshop-img/diagnostics1.jpg" alt="maintenens" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img className=' fit-img' src="/workshop-img/diagnostics2.jpg" alt="maintenens" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img className=' fit-img' src="/workshop-img/diagnostics3.jpg" alt="maintenens" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Diagnostics