
import AboutUs from "./components/AboutUs/AboutUs";
import Home from "./components/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Cars from "./components/Cars/Cars";
import SellCar from "./components/sellYourCar/SellCar";
import AcService from "./components/AcService/AcService";
import Services from "./components/Services/Services";
import Connect from "./components/Connect/Connect";
import Diagnostics from "./components/Services/Diagnostics";
import Repairs from "./components/Services/Repairs";
import ElectricalWorks from "./components/Services/ElectricalWorks";
import RecoveryServices from "./components/Services/RecoveryServices";
import CarDetailing from "./components/Services/CarDetailing";
import AutomaticTransmission from "./components/Services/AutomaticTransmission";
import ServiceMaintenance from "./components/Services/ServiceMaintenance";


function App() {
  
  return (
 <>
   <BrowserRouter>
    <Header/>
      <Routes>
          
          <Route path="/" index element={<Home />} />
          <Route path="/about" element={ <AboutUs/>} />
          <Route path="/cars" element={ <Cars/>} />
          <Route path="/sell" element={ <SellCar/>} />
          <Route path="/services" element={ <Services/>} />
          <Route path="/services/AirConditioner" element={ <AcService/>} />
          <Route path="/services/ServiceMaintenance" element={ <ServiceMaintenance/>} />
          <Route path="/services/diagnostics" element={ <Diagnostics/>} />
          <Route path="/services/repairs" element={ <Repairs/>} />
          <Route path="/services/electricalworks" element={ <ElectricalWorks/>} />
          <Route path="/services/recovery" element={ <RecoveryServices/>} />
          <Route path="/services/detailing" element={ <CarDetailing/>} />
          <Route path="/services/transmission" element={ <AutomaticTransmission/>} />
          <Route path="/contact" element={ <Connect/>} />

        
      </Routes>
      
      <Footer/>

    </BrowserRouter>
    

    
 </>
  );
}

export default App;
