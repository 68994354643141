import React from 'react'
import BreadcrumbUs from '../Breadcrumb/BreadcrumbUs'
import "./CarDetailing.css"
const CarDetailing = () => {
   
    return (
        <>
            <BreadcrumbUs title="-Car Detailing" subTitle="Car Detailing Service" currentPage="RECOVERY CAR DETAILING" prevesPage="SERVICE" />
            <div className="car-detailing">
                <div className="container">
                    <div className="row mx-auto mt-5">
                        <div className="col-md-10 ">

                            <ul className='mb-5'>
                                <li>
                                    BODY POLISHING
                                </li>
                                <li>
                                    INTERIOR CLEANING
                                </li>
                                <li>
                                    SURFACE REFINEMENT

                                </li>
                                <li>
                                    INTERNAL PANNEL PROTECTION

                                </li>
                                <li>
                                    UNDER BODY ANTI RUST TREATMENT

                                </li>
                                <li>
                                    A/C DISINFECTION

                                </li>
                                <li>
                                    SUNFILIM FIXING

                                </li>
                                <li>
                                    CERAMIC COATING

                                </li>
                                <li>
                                    SILENCER COATING

                                </li>
                            </ul>

                        </div>
                        <div className="col-md-2">

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default CarDetailing