import React from 'react'
import BreadcrumbUs from '../Breadcrumb/BreadcrumbUs'
import "./Repairs.css"
const Repairs = () => {
    return (

        <>
            <BreadcrumbUs title="- Repairs" subTitle="Vehicle Repairs" currentPage="REPAIRS" prevesPage="SERVICE" />

            <div className="repairs">
                
                <div className="container">
                    <div className="row mx-auto mt-5">
                        <div className="col-md-10">
                            <p className='introduction'>
                                At Das Auto Services we carry out a huge range of vehicle repairs from simply changing a bulb to engine removal and rebuild.
                            </p>
                            <h5 className='mb-3'>SUSPENSION</h5>
                            <ul>
                                <li>
                                    <a href="/services/repairs/#"> Front Suspension</a>
                                </li>
                                <li>
                                    <a href="/services/repairs/#">Rear Suspension</a>
                                </li>
                            </ul>
                            <h5 className='mb-3'>DIFFERENTIAL AND DRIVELINE</h5>
                            <ul>
                                <li>
                                    <a href="/services/repairs/#">Rear Wheel Drive System.</a>
                                </li>
                                <li>
                                    <a href="/services/repairs/#">Front Wheel Drive System</a>
                                </li>
                                <li>
                                    <a href="/services/repairs/#">Four Wheel Drive System.</a>
                                </li>
                            </ul>
                            <h5 className='mb-3'>BRAKES</h5>

                            <ul>
                                <li>
                                    <a href="/services/repairs/#">Base Brake System</a>
                                </li>
                                <li>
                                    <a href="/services/repairs/#">Four-Wheel Disc Brake System</a>
                                </li>
                            </ul>
                            <hr style={{ color: "white" }} className='mb-3' />

                            <h5 className='mb-3'>COOLING</h5>

                            <ul>
                                <li>
                                    <a href="/services/repairs/#">Engine cooling</a>
                                </li>
                            </ul>
                            <h5 className='mb-3'>ELECTRICAL</h5>

                            <ul>
                                <li>
                                    <a href="/services/repairs/#">Starting System</a>
                                </li>
                                <li>
                                    <a href="/services/repairs/#">Charging System</a>

                                </li>
                                <li>
                                    <a href="/services/repairs/#">Door-Mounted Power Systems</a>

                                </li>
                                <li>
                                    <a href="/services/repairs/#">  </a>

                                </li>

                            </ul>
                            <h5 className='mb-3'>EXHAUST SYSTEM</h5>

                            <ul>
                                <li>
                                    <a href="/services/repairs/#">Exhaust System</a>
                                </li>
                                <li>
                                    <a href="/services/repairs/#">Turbocharger</a>

                                </li>

                            </ul>
                            <hr style={{ color: "white" }} className='mb-3' />

                            <h5 className='mb-3'>FUEL SYSTEM</h5>
                            <ul>
                                <li>
                                    <a href="/services/repairs/#">Fuel System.</a>
                                </li>
                            </ul>
                            <h5 className='mb-3'>STEERING</h5>
                            <ul>
                                <li>
                                    <a href="/services/repairs/#">Steering Systems</a>
                                </li>
                            </ul>
                            <h5 className='mb-3'>TRANSMISSION</h5>
                            <ul>
                                <li>
                                    <a href="/services/repairs/#">Front Wheel Drive Automatic Transaxle</a>
                                </li>
                                <li>
                                    <a href="/services/repairs/#">Front Wheel Drive Manual Transaxle and Clutch</a>
                                </li>
                                <li>
                                    <a href="/services/repairs/#">Rear Wheel Drive Automatic Transmission</a>
                                </li>
                                <li>
                                    <a href="/services/repairs/#">Rear Wheel Drive Manual Transmission and Clutch</a>
                                </li>
                            
                            </ul>

                           <hr style={{ color: "white" }} className='mb-3' />

                           <h5 className='mb-3'>TIRES/WHEELS</h5>
                           <ul>
                            <li><a href="/services/repairs/#">Tire Wear Patterns.</a> </li>
                           
                           </ul>
                           <h5 className='mb-3'>HEATING AND AIR CONDITIONING</h5>
                           <ul>
                            <li><a href="/services/repairs/#">Heating System.</a> </li>
                            <li><a href="/services/repairs/#">Air Conditioning System</a> </li>
                           
                           </ul>
                           <hr style={{ color: "white" }} className='mb-3' />

                        </div>
                        <div className="col-md-2">
                            <div className="row mb-3">
                                <div className="col-md-12 col-sm-2">
                                    <img className='fit-img' src="/workshop-img/repair1.jpg" alt="maintenens" />
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img className=' fit-img' src="/workshop-img/repair2.jpg" alt="maintenens" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img className=' fit-img' src="/workshop-img/repair3.jpg" alt="maintenens" />
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Repairs