import React, {  useState } from 'react'
import "./Strategy.css"
import StrategyCarousel from '../Carousel/StrategyCarousel'
import { Collapse } from 'react-bootstrap'

import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";

const Strategy = () => {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  return (
    <div className='strategy'>

      <div className="row">
        <div className="col-md-6">
          <StrategyCarousel />
        </div>
        <div className="col-md-6 text-dang collapse-box pb-md-0 sm-5">
          <h2>- Frequently asked questions</h2>
          <div className='row'>
            <div className="col-md-12">

              <div className="py-2 collaps-content-div">
                <div className='collaps-content-subject'>
                  <h4 className='d-inline-block collapse-header'>
                    What is Preventative Maintenance?
                  </h4>

                  <Collapse in={open1}>
                    <div className=" collapse-content" id="example-collapse-text-1">
                      <p className='collapse-text'>
                        Preventative Maintenance is regular maintenance of your vehicle that helps keep your automobile running efficiently and eliminating potential problems that may leave you stranded.
                      </p>
                    </div>
                  </Collapse>
                </div>
                <button
                  className="collaps-btn"
                  onClick={() => setOpen1(!open1)}
                  aria-controls="example-collapse-text-1"
                  aria-expanded={open1}
                >
                  {open1 ? <FaMinus /> : <FaPlus />}
                </button>
              </div>

            </div>
          </div>
          <div className='row'>
            <div className="col-md-12">

              <div className="py-2 collaps-content-div">
                <div className='collaps-content-subject'>
                  <h4 className='d-inline-block collapse-header'>
                    What Maintenance Should I be doing and when?
                  </h4>
                  <Collapse in={open2}>
                    <div className=" collapse-content" id="example-collapse-text-2">
                      <p className='collapse-text'>
                        These are generic service recommendations based solely on time or mileage not on visual appearance or measurement.
                        Their purpose is to extend the life of your vehicle and help prevent breakdown.                      </p>
                    </div>
                  </Collapse>

                </div>
                <button className="collaps-btn"
                  onClick={() => setOpen2(!open2)}
                  aria-controls="example-collapse-text-2"
                  aria-expanded={open2}
                >
                  {open2 ? <FaMinus /> : <FaPlus />}
                </button>


              </div>

            </div>
          </div>
          <div className='row'>
            <div className="col-md-12">

              <div className="py-2 collaps-content-div">
                <div className='collaps-content-subject'>
                  <h4 className='d-inline-block collapse-header'>
                    How do I know when my car needs a tune up?
                  </h4>
                  <Collapse in={open3}>
                    <div className=" collapse-content" id="example-collapse-text-3">
                      <p className='collapse-text'>
                        You may see your gas mileage decrease. Your vehicle may start running rough.
                        If your check engine light comes on that could also be an indicator that your vehicle needs a tune up.                      </p>
                    </div>
                  </Collapse>

                </div>
                <button className="collaps-btn"
                  onClick={() => setOpen3(!open3)}
                  aria-controls="example-collapse-text-3"
                  aria-expanded={open3}
                >
                  {open3 ? <FaMinus /> : <FaPlus />}
                </button>


              </div>

            </div>
          </div>
          <div className='row'>
            <div className="col-md-12">

              <div className="py-2 collaps-content-div">
                <div className='collaps-content-subject'>
                  <h4 className='d-inline-block collapse-header'>
                    How should I prepare my car for a road trip?
                  </h4>
                  <Collapse in={open4}>
                    <div className=" collapse-content" id="example-collapse-text-4">
                      <p className='collapse-text'>
                        Check all lights. Check your headlights (high and low-beam),
                        brake lights, turn signals, parking lamps and (if you have them) fog lights to make sure they are all operational.                      </p>
                    </div>
                  </Collapse>

                </div>
                <button className="collaps-btn"
                  onClick={() => setOpen4(!open4)}
                  aria-controls="example-collapse-text-4"
                  aria-expanded={open4}
                >
                  {open4 ? <FaMinus /> : <FaPlus />}
                </button>


              </div>

            </div>
          </div>

        </div>
      </div>


    </div>
  )
}

export default Strategy