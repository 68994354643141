import React from 'react'
import BreadcrumbUs from '../Breadcrumb/BreadcrumbUs'
import Footer from '../Footer/ContactUs'
import "./Connect.css"
import { FaMapLocationDot } from "react-icons/fa6";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdAttachEmail } from "react-icons/md";
import Iframe from 'react-iframe';
import { Link } from 'react-router-dom';

const Connect = () => {
  return (
    <>
      <BreadcrumbUs title="- Contact Us " subTitle="Reach Out to Our Team" currentPage="CONTACT" />
      <div className='connect'>
        <div className="container-fluid">

          <div className="container">
            <div className="row ">

              <div className="col-md-12 ">
                <div className="row">
                  <div className="col-md-6 mt-5 p-5 mb-5 address-box ">
                    <h2>Let's get in touch</h2>
                    <p className='pt-3 fw-bold'>
                      We're open for any suggestion or
                      just to have a chat
                    </p>
                    <div className="row">
                      <div className="col-sm-1">
                        <span className='icon'>
                          <FaMapLocationDot />
                        </span>
                      </div>
                      <div className="col-11 p-0">
                        <span> <strong>Address</strong></span>
                        <p className='address'>
                          Industrial Area 4 - Industrial Area - <br />
                          Sharjah,United Arab Emirates
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-1">
                        <span className='icon'>
                          <Link to={'mailto:dasautoservices@gmail.com'}>
                            <div>
                              <MdAttachEmail />
                            </div>
                          </Link>

                        </span>
                      </div>
                      <div className="col-11 p-0">
                        <Link to={'mailto:dasautoservices@gmail.com'}>
                          <span> <strong>Email</strong></span>
                          <p className='contact-number'>
                            dasautoservices@gmail.com
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-1">
                        <span className='icon'>
                          <div className="rounded-icon">
                            <Link to={'tel:+971505080445'}>
                              <BsFillTelephoneFill />
                            </Link>

                          </div>
                        </span>
                      </div>
                      <div className="col-11 p-0">
                        <Link to={'tel:+971505080445'}>
                          <span> <strong>Phone</strong></span>
                          <p className='contact-number'>
                            +971 6532 3881
                          </p>
                        </Link>

                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-md-5">
                    <div className="row mx-4 ">
                      <div className="col-md-12 mt-5 ">
                        <Iframe
                          url="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Industrial%20Area%204%20-%20Industrial%20Area%20-%20Sharjah%20-%20United%20Arab%20Emirates+(Das%20Auto%20Services%20LLC)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                          width="100%"
                          height="350"
                          frameborder="0"
                          scrolling="no"
                          marginheight="0"
                          marginwidth="0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>

  )
}

export default Connect