import React from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik } from 'formik';



const SellCarForm = () => {


    const schema = yup.object().shape({
        carName: yup.string().required('Car name is required'),
        carModel: yup.string().required('Car model is required'),
        carYear: yup.string().required('Car year is required'),
        carBrand: yup.string().required('Car brand is required'),
        carCondition: yup.string(),
        carFuelType: yup.string(),
        carColor: yup.string(),
        additionalMessage: yup.string()
    });

    const handleSubmit = (values) => {
        console.log(values); // You can handle form submission here
    };

    const startYear = 1990; 
    const currentYear = new Date().getFullYear(); 
    const years = [];

    for (let year = currentYear; year >= startYear; year--) {
        years.push(year.toString());
    }


    
    const color = {
        "colors": [
            "Black",
            "White",
            "Silver",
            "Gray",
            "Red",
            "Blue",
            "Green",
            "Yellow",
            "Orange",
            "Purple",
            "Brown",
            "Gold",
            "Beige",
            "Bronze"
        ]
    }





    return (
        <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            initialValues={{
                carName: '',
                carModel: '',
                carYear: '',
                carBrand: '',
                carCondition: '',
                carColor: '',
                additionalMessage: ''
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors
            }) => (
                <Form noValidate onSubmit={handleSubmit}>
                    {/* // car name and model */}
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="carName">
                            <Form.Label>Car Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="carName"
                                value={values.carName}
                                onChange={handleChange}
                                isInvalid={touched.carName && !!errors.carName}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.carName}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="carModel">
                            <Form.Label>Car Model</Form.Label>
                            <Form.Control
                                type="text"
                                name="carModel"
                                value={values.carModel}
                                onChange={handleChange}
                                isInvalid={touched.carModel && !!errors.carModel}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.carModel}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    {/* car year and car brand */}
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="carYear">
                            <Form.Label>Car Year</Form.Label>
                            <Form.Control
                                as="select"
                                name="carYear"
                                value={values.carYear}
                                onChange={handleChange}
                                isInvalid={touched.carYear && !!errors.carYear}
                            >
                                <option value="">Select a car year</option>
                                {years.map((year, index) => (
                                    <option key={index} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {errors.carYear}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="carBrand">
                            <Form.Label>Car Brand</Form.Label>
                            <Form.Control
                                type="text"
                                name="carBrand"
                                value={values.carBrand}
                                onChange={handleChange}
                                isInvalid={touched.carBrand && !!errors.carBrand}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.carBrand}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    {/* car color and fuel type */}

                    <Row className="mb-3">
                        {/* //car color  */}
                        <Form.Group as={Col} controlId="carColor">
                            <Form.Label>Car Color</Form.Label>
                            <Form.Control
                                as="select"
                                name="carColor"
                                value={values.carColor}
                                onChange={handleChange}
                                isInvalid={touched.carColor && !!errors.carColor}
                            >
                                <option value="">Select a car color</option>
                                {color.colors.map((color, index) => (
                                    <option key={index} value={color}>{color}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        {/* Fuel Type */}
                       
                        <Form.Group as={Col} controlId="carFuelType">
                            <Form.Label>Fuel Type</Form.Label>
                            <Form.Control
                                as="select"
                                name="carFuelType"
                                value={values.carFuelType}
                                onChange={handleChange}
                                isInvalid={touched.carFuelType && !!errors.carFuelType}
                            >
                                <option value="">Select Fuel Type</option>
                                <option value="Gas">Gas</option>
                                <option value="Disel">Disel</option>
                                <option value="Electrical">Electrical</option>
                                <option value="Petrol">Petrol</option>
                                <option value="Hybrid">Hybrid</option>
                            </Form.Control>
                        </Form.Group>
                    </Row>

                    {/* car condition */}
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="carCondition">
                            <Form.Label>Car Condition</Form.Label>
                            <Form.Control
                                as="select"
                                name="carCondition"
                                value={values.carCondition}
                                onChange={handleChange}
                            >
                                <option value="">Select condition</option>
                                <option value="excellent">Excellent</option>
                                <option value="good">Good</option>
                                <option value="fair">Fair</option>
                                <option value="poor">Poor</option>
                            </Form.Control>
                        </Form.Group>
                    </Row>
                    {/* Additional message */}
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="additionalMessage">
                            <Form.Label>Additional Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                name="additionalMessage"
                                value={values.additionalMessage}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    </Row>

                    <Button type="submit">Submit</Button>
                </Form>
            )}
        </Formik>
    );
};

export default SellCarForm;
