import React from 'react'
import "./ContactUs.css"
const ContactUs = () => {
    return (
        <>
            <div className="top-footer">
                <div className="container">
                    <div className="row overflow-hidden ps-5 ps-md-0 ">
                        <div className="col-lg-7 col-md-4  text-div">
                            <span className='fs-5 texts'> <b className='fs-3'>-</b>Are you looking for a</span>
                            <br />
                            <span className='sec-text'> Car Service or Repair?</span>
                            <br />
                            <span className='last-text'>We got the experience and technology</span>
                        </div>
                        <div className="col-lg-2 col-md-3 call-us-div">
                            <div className=''>
                                <span> Call Us Now</span>
                                <br />
                                <a href='tel:+971505080445' className='text-decoration-none text-light'><span className='fs-5'>+971 6532 3881</span></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 call-us-div">
                            <div className='' >
                                <span> Email Us Now</span>
                                <br />
                                <a href='mailto:dasautoservices@gmail.com' className='text-decoration-none text-light' >
                                    <span className='fs-5'>dasautoservices@gmail.com</span>

                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="bottam-footer">
                <div className="row"></div>
            </div> */}
        </>
    )
}

export default ContactUs