import React from 'react'
import BreadcrumbUs from '../Breadcrumb/BreadcrumbUs'
import "./Services.css"
import { FaRegSnowflake } from "react-icons/fa";
import { IoCarSport } from "react-icons/io5";
import { BsLaptop } from "react-icons/bs";
import { FaUsersGear } from "react-icons/fa6";
import { SlLocationPin } from "react-icons/sl";
import { FaPlug } from "react-icons/fa";
import { Tb24Hours } from "react-icons/tb";
import { BsFillTicketDetailedFill } from "react-icons/bs";
import { GiAutomaticSas } from "react-icons/gi";
import { Link } from 'react-router-dom';



const Services = () => {
  return (

    <>
      <BreadcrumbUs title="- Services " subTitle="Services We Offer" currentPage="SERVICES" />

      <div className="service">
        <div className="container-fluid">

          <div className="container">
            <div className="row splash">

              <div className="col-md-10 mx-auto">
               
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row gx-0">

              <div className="col-md-10  mx-auto">
                <div className="row service-box">
                  <div className="col-md-1 service-conten-box .d-md-none">
                  </div>
                  <div className="col-md-2 service-conten-box">
                    <Link to="/services/AirConditioner">
                      <span className='service-icon lh-1'>
                        <FaRegSnowflake /><br />
                      </span>
                      <span className='service-title'> Air Conditioner</span>
                    </Link>

                  </div>
                  <div className="col-md-2 service-conten-box">
                    <Link to="/services/ServiceMaintenance" >
                      <span className='service-icon lh-1'>
                        <IoCarSport /><br />
                      </span>
                      <span className='service-title'>Services & Maintenance</span>
                    </Link>

                  </div>
                  <div className="col-md-2 service-conten-box">
                    <Link to="/services/diagnostics" >
                      <span className='service-icon lh-1'>
                        <BsLaptop /><br />
                      </span>
                    </Link>

                    <span className='service-title'>Diagnostics </span>
                  </div>
                  <div className="col-md-2 service-conten-box">
                    <Link to="/services/repairs">
                      <span className='service-icon lh-1'>
                        <FaUsersGear /><br />
                      </span >
                    </Link>

                    <span className='service-title'>Repair</span>
                  </div>
                  <div className="col-md-2 service-conten-box">
                    <Link to={"/contact"}>
                    <span className='service-icon lh-1'>
                      <SlLocationPin /><br />
                    </span>
                    <span className='service-title'>Our Location</span>
                    </Link>
                   
                  </div>
                  <div className="col-md-1 service-box">

                  </div>
                </div>
                <div className="row service-box">
                  <div className="col-md-1 service-conten-box ">

                  </div>
                  <div className="col-md-2 service-conten-box">


                  </div>
                  <div className="col-md-2 service-conten-box">


                  </div>
                  <div className="col-md-2 service-conten-box">


                  </div>
                  <div className="col-md-2 service-conten-box">
                  </div>
                  <div className="col-md-2 service-conten-box">
                  </div>
                  <div className="col-md-1 service-box">

                  </div>
                </div>
                <div className="row service-box">
                  <div className="col-md-1 service-conten-box ">
                  </div>
                  <div className="col-md-2 service-conten-box">
                    <Link to="/services/electricalworks">
                      <span className='service-icon lh-1'>
                        <FaPlug /><br />
                      </span>
                      <span className='service-title'>Electrical Works</span>
                    </Link>

                  </div>
                  <div className="col-md-2 service-conten-box">
                    <Link to="/services/recovery">
                      <span className='service-icon lh-1'>
                        <Tb24Hours /><br />
                      </span>
                      <span className='service-title'>24*7 Recovery Services</span>
                    </Link>
                  </div>
                  <div className="col-md-2 service-conten-box">
                    <Link to="/services/detailing">
                      <span className='service-icon lh-1'>
                        <BsFillTicketDetailedFill /><br />
                      </span>
                      <span className='service-title'>Car Detailing Service </span>
                    </Link>

                  </div>
                  <div className="col-md-2 service-conten-box">
                    <Link to="/services/transmission">
                      <span className='service-icon lh-1'>
                        <GiAutomaticSas /><br />
                      </span >
                      <span className='service-title'>Automatic Transmission</span>
                    </Link>

                  </div>
                  <div className="col-md-2 service-conten-box">
               
                  </div>
                  <div className="col-md-1 service-box">

                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="service-container"> */}


        </div>
      </div>
      {/* </div> */}

    </>


  )
}

export default Services