import React from 'react'
import BreadcrumbUs from '../Breadcrumb/BreadcrumbUs'
import "./RecoveryServices.css"
const RecoveryServices = () => {
    return (
        <>
            <BreadcrumbUs title="-Recovery Services" subTitle="24*7 Recovery Services" currentPage="RECOVERY SERVICES" prevesPage="SERVICE" />

            <div className="recovery-services">
                <div className="container">
                    <div className="row mx-auto m-5">
                        <div className="col-md-10">
                            <p className='recovery-services-sub-content'>
                                WE UNDERTAKE ATTANEDING CALLS RELATED TO PASSENGER CARS BREAK DOWN
                            </p>
                            <p className='recovery-services-sub-content'>
                            WE REACH BREAK DOWN SPOT, DIAGNOSIS AND REPAIR IN CASE IT IS A MINOR.IN CASE OF MAJOR REPAIR WE DRIVE  OR TOE  THE VEHICLE TO  <br/> OUR WORKSHOP
                            </p>
                            <p className='recovery-services-sub-content'>
                            OUR 24*7 ASSIATANT IS AVAILABLE AT DUBAI, SHARJAH AND AJMAN…
                            </p>
                        </div>
                        <div className="col-md-2 ">
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <img className=' fit-img' src="/workshop-img/Recovery1.jpg" alt="maintenens" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecoveryServices